import React, { createContext, useState } from "react"

const defaultState = {
  pagination: {
    page: null,
    number: 20,
    scrollY: null,
  },
}

export default function LabelleProvider({ children }) {
  const [pagination, setPagination] = useState(defaultState.pagination)
  const [filterProducts, setFilterProducts] = useState(null)
  const [tags, setTags] = useState(null)
  const [previousCollection, setPreviousCollection] = useState(
    defaultState.pagination
  )

  return (
    <LabelleContext.Provider
      value={{
        pagination,
        setPagination,
        previousCollection,
        setPreviousCollection,
        filterProducts,
        setFilterProducts,
        tags,
        setTags,
      }}
    >
      {children}
    </LabelleContext.Provider>
  )
}
export const LabelleContext = createContext(defaultState)
