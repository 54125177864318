import React, { createContext, useEffect, useState } from "react"
import axios from "axios"

export default function DiscountProvider({ children }) {
  const [discountActive, setDiscountActive] = useState(null)

  useEffect(() => {
    axios
      .get(`${process.env.GATSBY_STRAPI_API_URL}/api/shopify/discounts`)
      .then(result => {
        const data = result.data

        if (data?.rules?.type) {
          setDiscountActive(data)
        }
      })
      .catch(error => console.error(error))
  }, [])
  return (
    <DiscountContext.Provider value={{ discountActive }}>
      {children}
    </DiscountContext.Provider>
  )
}
export const DiscountContext = createContext()
