import React from "react"
import LabelleProvider from "@context/labelle"
import { QueryClient, QueryClientProvider } from "react-query"
import DiscountProvider from "@context/discount"
const queryClient = new QueryClient()

export const wrapRootElement = ({ element }) => {
  return (
    <LabelleProvider>
      <DiscountProvider>
        <QueryClientProvider client={queryClient}>
          {element}
        </QueryClientProvider>
      </DiscountProvider>
    </LabelleProvider>
  )
}
